import galleryBanner from "../../assets/images/contact/contact.jpg";
import Contact from "../../components/contact/Contact";

const data = {
  pagename: "Contact Us",
  img: galleryBanner,
};
function ContactPage() {
  return (
    <>
      <Contact data={data} />
    </>
  );
}
export default ContactPage;
