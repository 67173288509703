import { Link, useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo/henna-logo.png"
import { useState } from "react"
import axios from "axios"
import { Loaders } from "../Loaders"
function SignUpPage() {
    const [state, setState] = useState({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        password: ""
    })
    const [loader, setLoader] = useState(false)
    const handleChange = (e) => {
        const clone = { ...state }
        clone[e.target.name] = e.target.value
        setState(clone)
    }
    const Navigate = useNavigate()
    const submitData = async () => {
        setLoader(true)
        try {
            const res = await axios.post('https://abaris-j-p-frontend.vercel.app/front/user/register' , state)
            // alert('registration successful')
            Navigate('/login')
        } catch (error) {
            alert('Not Register')
        }
        setLoader(false)
    }
    return <div>
         {loader && <Loaders />}
        <div className="job_up_heading"></div>
        <div className="blog_container">
            <div className="blog_heading_sec">
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Sign up Page</h1>
            </div>

            <div className="login-page">
                <div className="form">
                    <div className="" style={{ width: "100%" }}>
                        <img src={logo} alt="" style={{ width: "50%", margin: "0 auto" }} className="logo" />
                    </div>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">First name</label>
                            <input type="text" className="form-control" name="firstname" value={state.firstname} onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Last name</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" name="lastname" value={state.lastname} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" name="email" value={state.email} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Mobile</label>
                            <input type="number" className="form-control" id="exampleInputEmail1" name="mobile" value={state.mobile} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Password</label>
                            <input type="password" className="form-control" id="exampleInputEmail1" name="password" value={state.password} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <button type="button" onClick={submitData}>Registraton</button>
                        <p className="message">
                            registered? <Link to="/login">Login</Link>
                        </p>
                    </form>

                </div>
            </div>

        </div>
    </div>
}
export default SignUpPage