import indimg from "../../assets/images/contact/country.png";
import ksaimg from "../../assets/images/contact/flag.png";
import uaeimg from "../../assets/images/contact/united-arab-emirates.png";
import locationpin from "../../assets/images/contact/location-pin.png";

export const IndcountData = {
  title: "India Office",
  img: indimg,
  list: [
    {
      id: "1",
      locationPin: locationpin,
      heading: "Corporate Office (Delhi)",

      address:
        "57-B, Lane 3, opp. Deep Sweets Corner, Bharat Nagar, New Friends Colony, New Delhi – 110025",
      email: " contact@hennainternational.co.in",

      phone1: "+91 9870353974, +91 11-41049392",
    },
    {
      id: "2",
      locationPin: locationpin,
      heading: "Branch Office(Mumbai)",
      address:
        "Ozone Biz centre, office no. 1210, 12th floor, belasis road, mumbai central(E) – 400008",
      email: " bd@hennainternational.co.in",
      phone1: " +91 22 31614893, 9999112385",
    },
  ],
};

export const ksaCountData = {
  title: "KSA Office",
  img: ksaimg,
  list: [
    {
      id: "1",
      locationPin: locationpin,
      heading: "Client Servicing Office",
      address: "Old Industrial Area, AL-Owaidah, Riyadh",
      email: " hennainternational@mockup4client.com",
      phone1: "+966 572847378",
    },
  ],
};

export const uaeCountData = {
  title: "UAE Office",
  img: uaeimg,
  list: [
    {
      id: "1",
      locationPin: locationpin,
      heading: "Client Servicing Office",
      address:
        "Ozone Biz Center, Office No. 1301, 13th Floor Belasis Road, Central, - 400 008.",
      email: " hennainternational@mockup4client.com",
      phone1: "+91-22-23028888",
    },
  ],
};
