
import {
    MdFilterAlt,
    MdEngineering,
    MdOutlineEngineering,
} from "react-icons/md";
import { RxCaretUp, RxCaretRight, RxCaretDown } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { HiBuildingOffice } from "react-icons/hi2";

import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { FaGoogle, FaInstagramSquare } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
function createMarkup(data) {
    return { __html: data };
}
function CompanyDetail({ data }) {

    return (
        <>
            <div className="job_up_heading"></div>
            <div className="jobs_main_sec">
                <div className="total_people">
                    <h5 className="" style={{ marginLeft: "13px" }}>
                        Company Details
                    </h5>
                </div>
                <div className="job_feild_sec">
                    <div className="col-lg-8 right_col">
                        <div className="job_feild_right_col mb-3">
                            <div className="date_posted mb-2">
                                <div
                                    className="d-flex"
                                    style={{ alignItems: "center", justifyContent: "center" }}
                                >
                                    <MdEngineering className="me-2 job_posted_geading_icon" />
                                    <div>
                                        <p
                                            style={{ fontWeight: "600", fontSize: "" }}
                                            className="jobdetails_box_title"
                                        >
                                            {data?.name}
                                        </p>
                                        <p className="jobdetails_box_title">
                                            <small className="mb-zero">
                                                <a href={data?.website} target="blank">{data?.name}</a>
                                            </small>
                                        </p>
                                        <p className="jobdetails_box_title">
                                            <small className="mb-zero">
                                                CEO : {data?.ceo}
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <RxCaretRight className="caretup" />
                                </div>
                            </div>

                            <div className="job_locat">
                                <IoLocationOutline className="me-2" />
                                <small>{data?.location}</small>
                            </div>
                            {/* 
                            <div className="job_locat">
                                <GrCurrency className="me-2" />
                                <small style={{ fontSize: "17px" }}>
                                    ₹15,000 - ₹80,000 monthly
                                </small>
                            </div>
                            <div className="data_table mt-3">
                                <div className="row">
                                    <div className="col">
                                        <h5>
                                            <small>Fixed</small>
                                        </h5>
                                        <h6>₹15000 - ₹80000</h6>
                                    </div>
                                    <div className="col">
                                        <h5>
                                            <small>Fixed</small>
                                        </h5>
                                        <h6>₹15000 - ₹80000</h6>
                                    </div>
                                    <div className="col"></div>
                                </div>
                            </div> */}

                            <ul className="job_category_list">
                                <li className="jobcategory_list_item">
                                    <HiBuildingOffice
                                        className="me-1"
                                        style={{ fontSize: "20px" }}
                                    />
                                    Work from Office
                                </li>
                                <li className="jobcategory_list_item">
                                    <HiBuildingOffice
                                        className="me-1"
                                        style={{ fontSize: "20px" }}
                                    />
                                    Full Time
                                </li>
                                <li className="jobcategory_list_item">
                                    <HiBuildingOffice
                                        className="me-1"
                                        style={{ fontSize: "20px" }}
                                    />
                                    Freshers Only
                                </li>
                                <li className="jobcategory_list_item">
                                    <HiBuildingOffice
                                        className="me-1"
                                        style={{ fontSize: "20px" }}
                                    />
                                    Basic English
                                </li>
                            </ul>

                            
                            <div className="apply_job_container">

                                <div className="icons">
                                    <h3 className="address_box_title">connect with us</h3>
                                    <ul className="d-flex connect_icons_list">
                                        <li>
                                            <a href={data?.facebook} target="blank">
                                                <FaFacebook className="connect_icons_list_item" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={data?.linkedin} target="blank">
                                                <FaLinkedin className="connect_icons_list_item" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={data?.google_plus} target="blank">
                                                <FaGoogle className="connect_icons_list_item" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={data?.pinterest} target="blank">
                                                <FaInstagram className="connect_icons_list_item" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="apply_job_container" style={{ margin: "20px 0" }}>
                                <button className="apply_job_btn">Apply For Job</button>
                                <button className="btn_share">Share</button>
                            </div>
                        </div>
                        <div className="job_feild_right_col mb-3">
                            <div className="job_highlight_box">

                                <div className="applicant_box">
                                    <h4>{data?.name}</h4>
                                    <p>
                                        {data?.description}
                                    </p>
                                </div>
                                <h6> Email :    {data?.email}</h6>
                                <h6> Number :    {data?.phone}</h6>
                                <h6> Fax :    {data?.fax}</h6>
                            </div>
                            <div className="job_all_list_sec"></div>
                            <div className="require_description mt-3">
                                <h5>Job Openings</h5>
                            </div>
                            <div className="style-descriptiontext">
                                <ul className="searchList">
                                    {/*Job start*/}
                                    <li>
                                        <div className="row">
                                            <div className="col-lg-9 col-md-8">
                                                <div className="jobimg">
                                                    <a
                                                        href="#"
                                                        title="Php Developer Required"
                                                    >
                                                        {" "}
                                                        <img
                                                            style={{ width: '100px', height: "100px", marginRight: "10px" }}
                                                            src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                                                        // alt="Hotel Tirupati Classic"
                                                        // title="Hotel Tirupati Classic"
                                                        />{" "}
                                                    </a>
                                                </div>
                                                <div className="jobinfo">
                                                    <h3>
                                                        <a
                                                            href="#"
                                                            title="Php Developer Required"
                                                        >
                                                            Php Developer Required
                                                        </a>
                                                    </h3>
                                                    {/* <div className="companyName" style={{margin:"12px 0"}}>
                                                        <a
                                                            href="#"
                                                            title="Hotel Tirupati Classic"
                                                        >
                                                            Hotel Tirupati Classic
                                                        </a>
                                                    </div> */}
                                                    <div className="location">
                                                        <label className="fulltime" title="Full Time/Permanent">
                                                            Full Time/Permanent
                                                        </label>
                                                        <label className="partTime" title="First Shift (Day)">
                                                            First Shift (Day)
                                                        </label>
                                                        - <span>Alabaster</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-3 col-md-4">
                                                <div className="listbtn">
                                                    <a href="#">
                                                        View Detail
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                            tellus libero, rutrum eleifend enim vitae, aliquam fermentum tortor. Morbi
                                            facili...
                                        </p>
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-lg-9 col-md-8">
                                                <div className="jobimg">
                                                    <a
                                                        href="#"
                                                        title="Php Developer Required"
                                                    >
                                                        {" "}
                                                        <img
                                                            style={{ width: '100px', height: "100px", marginRight: "10px" }}
                                                            src="https://hsalghanimdelivery.com/admin_assets/no-image.png"
                                                        // alt="Hotel Tirupati Classic"
                                                        // title="Hotel Tirupati Classic"
                                                        />{" "}
                                                    </a>
                                                </div>
                                                <div className="jobinfo">
                                                    <h3>
                                                        <a
                                                            href="#"
                                                            title="Php Developer Required"
                                                        >
                                                            Php Developer Required
                                                        </a>
                                                    </h3>
                                                    {/* <div className="companyName" style={{margin:"12px 0"}}>
                                                        <a
                                                            href="#"
                                                            title="Hotel Tirupati Classic"
                                                        >
                                                            Hotel Tirupati Classic
                                                        </a>
                                                    </div> */}
                                                    <div className="location">
                                                        <label className="fulltime" title="Full Time/Permanent">
                                                            Full Time/Permanent
                                                        </label>
                                                        <label className="partTime" title="First Shift (Day)">
                                                            First Shift (Day)
                                                        </label>
                                                        - <span>Alabaster</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-3 col-md-4">
                                                <div className="listbtn">
                                                    <a href="#">
                                                        View Detail
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                            tellus libero, rutrum eleifend enim vitae, aliquam fermentum tortor. Morbi
                                            facili...
                                        </p>
                                    </li>
                                    {/*Job end*/}

                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="job_feild_left_col">
                            <div className="">
                                <h6 className="d-flex" style={{ alignItems: "center" }}>
                                    {/* <MdFilterAlt className="filter" /> */}
                                    Company Detail
                                </h6>
                            </div>
                            <div className="date_posted mb-2">
                                <ul>
                                    <li className="companyss">
                                        <h5>Is Email Verified</h5>
                                        <h5>{data?.email_verified_at ? 'YES' : 'NO'}</h5>
                                    </li>
                                    <li className="companyss">
                                        <h5>Total Employees</h5>
                                        <h5>1-10</h5>
                                    </li>
                                    <li className="companyss">
                                        <h5>Established In</h5>
                                        <h5>{data?.established_in}</h5>
                                    </li>
                                    <li className="companyss">
                                        <h5>Current jobs</h5>
                                        <h5>{data?.availed_jobs_quota}</h5>
                                    </li>
                                    <li className="companyss">
                                        <h5>Current No of offices</h5>
                                        <h5>{data?.no_of_offices}</h5>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div>
                            <div
                                className="products-details-tab-content"
                                dangerouslySetInnerHTML={createMarkup(
                                    data?.map
                                )}
                                style={{ width: "100%", overflow: "hidden" }}
                            />
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.89796906043!2d77.04417144403125!3d28.527554409630003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1713358797437!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CompanyDetail;
