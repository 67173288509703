import { IoCallOutline } from "react-icons/io5";

import facebook from "../../../../assets/images/social-icon/facebook.png";
import twitter from "../../../../assets/images/social-icon/twitter.png";
import linkdin from "../../../../assets/images/social-icon/linkedin.png";
import calling from "../../../../assets/images/social-icon/phone-call.png";

function TopHeader() {
  return (
    <>
      <section className="topheader_sec">
        <div className="top_header_main_sec">
          <div className="container-fluid">
            <div className="row top_header_row">
              <div className="col">
                <div className="top_header_contact_no_box">
                  <IoCallOutline /> +91 1146593286
                </div>
              </div>
              <div className="col">
                <div className="top_header_global_icons_box">
                  <ul className="global_icon_list">
                    <li className="global_icon_list_item">
                      <a href="" className="flag_icons_social_glob">
                        <img
                          src={facebook}
                          alt=""
                          className="socail_icon_img"
                        />
                      </a>
                    </li>
                    <li className="global_icon_list_item">
                      <a href="" className="flag_icons_social_glob">
                        <img src={twitter} alt="" className="socail_icon_img" />
                      </a>
                    </li>
                    <li className="global_icon_list_item">
                      <a href="" className="flag_icons_social_glob">
                        <img src={linkdin} alt="" className="socail_icon_img" />
                      </a>
                    </li>
                    <li className="global_icon_list_item">
                      <a href="" className="flag_icons_social_glob">
                        <img src={calling} alt="" className="socail_icon_img" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default TopHeader;
