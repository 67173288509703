import { CiSearch } from "react-icons/ci";
import {
  MdFilterAlt,
  MdEngineering,
  MdOutlineEngineering,
} from "react-icons/md";
import { RxCaretUp, RxCaretRight, RxCaretDown } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";
import { HiBuildingOffice } from "react-icons/hi2";
import { PiSuitcaseSimpleDuotone } from "react-icons/pi";
import { FaUsers } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function JobDetails() {
  const param = useParams();
  const [data, setData] = useState()
  const getData = async () => {
    try {
      const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/jobs/get/${param.id}`)
      setData(res.data)
    } catch (error) {

    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className="job_up_heading"></div>
      <div className="jobs_main_sec">
        <div className="total_people">
          <h5 className="" style={{ marginLeft: "13px" }}>
            Job Details
          </h5>
        </div>
        <div className="job_feild_sec">
          <div className="col-lg-8 right_col">
            <div className="job_feild_right_col mb-3">
              <div className="date_posted mb-2">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <MdEngineering className="me-2 job_posted_geading_icon" />
                  <div>
                    <p
                      style={{ fontWeight: "600", fontSize: "" }}
                      className="jobdetails_box_title"
                    >
                    {data?.job_title}
                    </p>
                    <p className="jobdetails_box_title">
                      <small className="mb-zero">
                      {data?.company_id?.name}
                      </small>
                    </p>
                  </div>
                </div>
                <div>
                  <RxCaretRight className="caretup" />
                </div>
              </div>

              <div className="job_locat">
                <IoLocationOutline className="me-2" />
                <small>{data?.country_id?.country} {data?.states_id?.state} {data?.city_id?.city}</small>
              </div>

              <div className="job_locat">
                <GrCurrency className="me-2" />
                <small style={{ fontSize: "17px" }}>
                ₹{data?.salary_from} - ₹{data?.salary_to} monthly
                </small>
              </div>
              <div className="data_table mt-3">
                <div className="row">
                  <div className="col">
                    <h5>
                      <small>Fixed</small>
                    </h5>
                    <h6>₹{data?.salary_from} - ₹{data?.salary_to}</h6>
                  </div>
                  
                  <div className="col"></div>
                </div>
              </div>

              <ul className="job_category_list">
                <li className="jobcategory_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Work from Office
                </li>
                <li className="jobcategory_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Full Time
                </li>
                <li className="jobcategory_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Freshers Only
                </li>
                <li className="jobcategory_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Basic English
                </li>
              </ul>

              <div className="apply_job_container">
                <button className="apply_job_btn">Apply For Job</button>
                <button className="btn_share">Share</button>
              </div>
            </div>
            <div className="job_feild_right_col mb-3">
              <div className="job_highlight_box">
                <h5>Job Highlights</h5>
                <div className="applicant_box">
                  <p>
                    <FaUsers className="many_users_icon" /> 172 applicants
                  </p>
                </div>
              </div>
              <div className="job_all_list_sec"></div>
              <div className="require_description mt-3">
                <h5>Job Description</h5>
                <h6>{data?.job_description}</h6>
              </div>
              <div className="style-descriptiontext">
                <p className="rewuired_skill">
                  Required Skill :<br /> <br />
                  {/* FrontEnd Developer */}
                  <br />
                  <br />{data?.skills_id?.job_skill} <br />
                  {/* JS JQuery <br />
                  Tailwind CSS
                  <br /> next.js <br />
                  React.js <br />
                  <br /> */}
                  </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="job_feild_left_col">
              <div className="">
                <h6 className="d-flex" style={{ alignItems: "center" }}>
                  {/* <MdFilterAlt className="filter" /> */}
                  Similar Jobs{" "}
                </h6>
              </div>
              <div className="date_posted mb-2">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <MdEngineering className="me-2" />
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Mechanical Engineer
                    </p>
                    <p className="job_box_title">
                      <small className="mb-zero">Nein Automation</small>
                    </p>
                  </div>
                </div>
                <div>
                  <RxCaretRight className="caretup" />
                </div>
              </div>

              <div className="job_locat">
                <IoLocationOutline className="me-2" />
                <small>Ambattur Industrial Estate, Chennai</small>
              </div>

              <div className="job_locat">
                <GrCurrency className="me-2" />
                <small style={{ fontSize: "17px" }}>
                  ₹100,000 - ₹149,999 monthly
                </small>
              </div>

              <ul className="job_category_list">
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Work from Office
                </li>
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Full Time
                </li>
                <li className="job_details_category_list_item">
                  <PiSuitcaseSimpleDuotone
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Freshers Only
                </li>
              </ul>

              <div className="job_all_list_sec"></div>

              <div className="date_posted mb-2">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <MdEngineering className="me-2" />
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Software / Web Development
                    </p>
                    <p className="job_box_title">
                      <small className="mb-zero">Infinitro Technologies</small>
                    </p>
                  </div>
                </div>
                <div>
                  <RxCaretRight className="caretup" />
                </div>
              </div>

              <div className="job_locat">
                <IoLocationOutline className="me-2" />
                <small>Cbi Ganganagara, Bengaluru</small>
              </div>

              <div className="job_locat">
                <GrCurrency className="me-2" />
                <small style={{ fontSize: "17px" }}>
                  ₹10,000 - ₹30,000 monthly
                </small>
              </div>

              <ul className="job_category_list">
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Work from Office
                </li>
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Full Time
                </li>
                <li className="job_details_category_list_item">
                  <PiSuitcaseSimpleDuotone
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Freshers Only
                </li>
              </ul>

              <div className="job_all_list_sec"></div>

              <div className="date_posted mb-2">
                <div
                  className="d-flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <MdEngineering className="me-2" />
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      PHP Developer
                    </p>
                    <p className="job_box_title">
                      <small className="mb-zero">Nextweb Technologies</small>
                    </p>
                  </div>
                </div>
                <div>
                  <RxCaretRight className="caretup" />
                </div>
              </div>

              <div className="job_locat">
                <IoLocationOutline className="me-2" />
                <small>Kalyan Nagar, Bengaluru/Bangalore</small>
              </div>

              <div className="job_locat">
                <GrCurrency className="me-2" />
                <small style={{ fontSize: "17px" }}>
                  ₹20,000 - ₹70,000 monthly
                </small>
              </div>

              <ul className="job_category_list">
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Work from Office
                </li>
                <li className="job_details_category_list_item">
                  <HiBuildingOffice
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Full Time
                </li>
                <li className="job_details_category_list_item">
                  <PiSuitcaseSimpleDuotone
                    className="me-1"
                    style={{ fontSize: "20px" }}
                  />
                  Freshers Only
                </li>
              </ul>

              <button className="show_more_btn mt-3">
                Show More <RxCaretDown style={{ fontSize: "20px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JobDetails;
