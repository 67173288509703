import TopHeader from "./topHeader/TopHeader";
import Upperheader from "./upperHeader/UpperHeader";

function Header() {
  return (
    <>
      <div className="main_header_sec">
        <TopHeader />
        <Upperheader />
      </div>
    </>
  );
}
export default Header;
