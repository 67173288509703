import { Link } from "react-router-dom"
import logo from "../../assets/images/logo/henna-logo.png"
import { useEffect, useState } from "react"
import axios from "axios"
import { Loaders } from "../Loaders"
function ProfilePage() {
    const [state, setState] = useState({
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
    })
    const [loader, setLoader] = useState(false)
    const getData = async ()=>{
        setLoader(true)
        try {
            const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/user/get/${window.localStorage.getItem('userId')}`)
           setState({
            firstname: res?.data?.getaUser?.firstname,
            lastname: res?.data?.getaUser?.lastname,
            email: res?.data?.getaUser?.email,
            mobile: res?.data?.getaUser?.mobile,
        })
        } catch (error) {
            alert('error ')
        }
        setLoader(false)
    }
    useEffect(()=>{
        getData()
    },[])
    const handleChange = (e) => {
        const clone = { ...state }
        clone[e.target.name] = e.target.value
        setState(clone)
    }
    const submitData = async () => {
        setLoader(true)
        try {
            const res = await axios.put(`https://abaris-j-p-frontend.vercel.app/front/user/edit/${window.localStorage.getItem('userId')}` , state,{
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                  },
            })
           
        } catch (error) {
            alert('Not Register')
        }
        setLoader(false)
    }
    return <div>
         {loader && <Loaders />}
        <div className="job_up_heading"></div>
        <div className="blog_container">
            <div className="blog_heading_sec">
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Profile Page</h1>
            </div>

            <div className="login-page login-page2">
                <div className="form">
                    {/* <div className="" style={{ width: "100%" }}>
                        <img src={logo} alt="" style={{ width: "50%", margin: "0 auto" }} className="logo" />
                    </div> */}
                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">First name</label>
                            <input type="text" className="form-control" name="firstname" value={state.firstname} onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Last name</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" name="lastname" value={state.lastname} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" name="email" value={state.email} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Mobile</label>
                            <input type="number" className="form-control" id="exampleInputEmail1" name="mobile" value={state.mobile} onChange={handleChange} aria-describedby="emailHelp" />
                        </div>
                        <button type="button" onClick={submitData}>Update</button>
                       
                    </form>

                </div>
            </div>

        </div>
    </div>
}
export default ProfilePage