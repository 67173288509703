
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CompanyDetail from "../../components/companydetail/CompanyDetail";
function CompanyDetails() {
    const param = useParams();
    const [data, setData] = useState()
    const getData = async () => {
        try {
            const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/company/get/${param.id}`)
            setData(res.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        getData()
    }, [])
    return <div>
        <CompanyDetail data={data}/>
    </div>
}
export default CompanyDetails