import AboutUs from "../../components/aboutUs/AboutUs";

import aboutImg from "../../assets/images/aboutus-img/page-title-aboutus.jpg";
const data = {
  pagename: "About Us",
  img: aboutImg,
};

function AboutUsPage() {
  return (
    <>
      <AboutUs data={data} />
    </>
  );
}
export default AboutUsPage;
