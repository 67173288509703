import Jobs from "../../components/jobs/Jobs";

function JobsPage() {
  return (
    <>
      <Jobs />
    </>
  );
}
export default JobsPage;
