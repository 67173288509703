import img1 from "../../../assets/images/job-grid/01.webp";
import img2 from "../../../assets/images/job-grid/02.webp";
import img3 from "../../../assets/images/job-grid/03.webp";
import img4 from "../../../assets/images/job-grid/04.png";
import img5 from "../../../assets/images/job-grid/05.webp";
import img6 from "../../../assets/images/job-grid/06.webp";
import { BiBadgeCheck } from "react-icons/bi";

export const recentJobsData = {
  heading: "35k+ Recent Jobs Available",
  title:
    "Unlock over 35,000 recent job opportunities waiting for your expertise. Our job portal curates a dynamic list of openings",
  jobs: [
    {
      id: "1",
      tagNormal: "FULL TIME",
      tagUrgent: "URGENT",
      time: "5 Months Ago",
      img: img1,
      jobInstructor: "Senior Graphics Designer",
      companyName: "InnoTech Solutions",
      icon: <BiBadgeCheck />,
      location: "Florida",
      currency: "18k - 30k",
      experience: "Experience : 5 Years",
      skills1: "Photoshop",
      skills2: "Illustrator",
      salaryDeadLine: "15 Days Left To Apply",
    },
    {
      id: "2",
      tagNormal: "PART TIME",
      tagUrgent: "FEATURED",
      time: "2 Months Ago",
      img: img2,
      jobInstructor: "Senior/Junior Web Developer",
      companyName: "Data Dynamics",

      location: "New Jersey",
      currency: "2.5k - 5k",
      experience: "Experience : 3 Years",
      skills1: "HTML",
      skills2: "CSS",
      salaryDeadLine: "15 Days Left To Apply",
    },
    {
      id: "3",
      tagNormal: "INTERNSHIPS",
      tagUrgent: "CLOSED",
      time: "15 Days Ago",
      img: img3,
      jobInstructor: "Cloud Systems Inc",
      companyName: "InnoTech Solutions",
      logo: <BiBadgeCheck />,
      location: "New Mexico",
      currency: "1.3k - 2k",
      experience: "Experience : 5 Years",
      skills1: "Brand Management",
      skills2: "Analytical Skills",
      salaryDeadLine: "15 Days Left To Apply",
    },
    {
      id: "4",
      tagNormal: "REMOTE",
      tagUrgent: "ADS",
      time: "20 Days Ago",
      img: img4,
      jobInstructor: "Senior Creative Director",
      companyName: "Tech Innovate Labs",

      location: "Florida",
      currency: "1.3k - 2k",
      experience: "Experience : 1 Years",
      skills1: "Leadership",
      skills2: "Creative Vision",
      salaryDeadLine: "15 Days Left To Apply",
    },
    {
      id: "5",
      tagNormal: "FULL TIME",
      tagUrgent: "URGENT",
      time: "25 Days Ago",
      img: img5,
      jobInstructor: "Senior Graphics Designer",
      companyName: "InnoTech Solutions",
      logo: <BiBadgeCheck />,
      location: "Texas",
      currency: "1.6k - 2.4k",
      experience: "Experience : 3 Years",
      skills1: "Financial Analysis",
      skills2: "Strategic Planning",
      salaryDeadLine: "15 Days Left To Apply",
    },
    {
      id: "6",
      tagNormal: "FULL TIME",
      tagUrgent: "FEATURED",
      time: "1 Months Ago",
      img: img6,
      jobInstructor: "Creative Experience Chefs",
      companyName: "GreenTech Innovations",

      location: "West Virginia",
      currency: "18k - 30k",
      experience: "Experience : 1 Years",
      skills1: "Flavor Pairing",
      skills2: "Cultural Fusion",
      salaryDeadLine: "15 Days Left To Apply",
    },
  ],
};
