import recruit from "../../../assets/images/banner/Recruitment.png";
import Consulting from "../../../assets/images/banner/Consulting.png";
import skill from "../../../assets/images/banner/I 2.png";
import me from "../../../assets/images/banner/me.png";
import { CiLocationOn } from "react-icons/ci";
function BannerDetails() {
  return (
    <>
      <div className="banner_details_container" data-aos="fade-right">
        <h2 className="banner_detail_heading">
          <small style={{ color: "#FE9905" }}>2500 </small>
          Thousands Dream <small>Jobs Available Now</small>{" "}
        </h2>
        <ul className="d-flex recruit_list">
          <li>
            <img src={recruit} alt="" className="recruit_png" />
            Recruitment
          </li>
          <li>
            <img src={Consulting} alt="" className="recruit_png" />
            Consulting
          </li>
          <li>
            <img src={skill} alt="" className="recruit_png" />
            Skill Up-Gradation
          </li>
        </ul>

        <div className="job_hero_form">
          <div className="form_select_box">
            <select class="form_select" aria-label="Default select example">
              <option selected>Job Categories</option>
              <option defaultValue="1">One</option>
              <option defaultValue="2">Two</option>
              <option defaultValue="3">Three</option>
            </select>
          </div>
          <div className="select_category">
            <input
              type="text"
              placeholder="Location"
              className="input_job_hero_form"
            />
            <CiLocationOn className="input_job_hero_form_icon" />
          </div>
        </div>
      </div>
      <div className="me_fram_card" data-aos="fade-left">
        <img src={me} alt="" className="me_img" />
      </div>
    </>
  );
}
export default BannerDetails;
