import { IoArrowForward, IoLocationOutline } from "react-icons/io5";
import { FaCalendarWeek } from "react-icons/fa";
// import seniorGraph from "../../../assets/images/job-grid/01.webp";
// import { BiBadgeCheck } from "react-icons/bi";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { PiBriefcase } from "react-icons/pi";
import { GoHistory } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function RecentJobsAvailable({ jobData }) {
  const [data, setData] = useState()
  const [count, setCount] = useState([])
  const [count1, setCount1] = useState(1)
  const getData = async () => {
    try {
      const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/jobs/featured`)
      setData(res.data.data)
      const arrr = []
      for (let i = 0; i < res.data.totalPages; i++) {
        arrr.push(i +1)
      }
      setCount(arrr);
    } catch (error) {

    }
  }
  useEffect(() => {
    getData()
  }, [])


  const getData2 = async (num) => {
    try {
      const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/jobs/featured?page=${num}`)
      setData(res.data.data)
     
    } catch (error) {

    }
  }

  const getNextPage = (num)=>{
    setCount1(num)
    getData2(num)
  }
  return (
    <>
      <div className="job_area">
        <div className="container">
          <div
            className="row"
            style={{ alignItems: "center", marginBottom: "45px" }}
          >
            <div className="col-xl-5">
              <div className="section-title">
                <h2>Featured Jobs</h2>
                <p>{jobData.title}</p>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="all_job_btn_sec">
                <a href="" className="b-btn">
                  Browse All Jobs <IoArrowForward />
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            {!data?.length && <h5>NO DATA</h5>}
            {data && data.map((item) => {
              return (
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="job_wrapper">
                    <div className="job-seccetion">
                      <div className="job-tag">
                        <span className="tag_normal">{item?.job_type_id?.job_type}</span>
                        <span className="tag_normal">{item?.job_shift_id?.job_shift}</span>
                        <span className="tag_normal">{item?.functional_area_id?.functional_area}</span>
                      </div>
                      <div className="pasted-time">
                        <span>
                          <FaCalendarWeek className="calander_week" />
                          {item.createdAt}
                        </span>
                      </div>
                    </div>
                    <div className="job-instructor-profile">
                      <div className="job-instructor-img">
                        <img src={jobData.jobs[0].img} alt="" />
                      </div>
                      <div className="job-instructor-title">
                        <h4>
                          <a href="">{item.job_title}</a>
                        </h4>
                        <a href="#" className="company_name">
                          {item.companyName} {item.icon}
                        </a>
                        <h6 className="florida">
                          <IoLocationOutline
                            style={{
                              fontSize: "20px",
                              marginRight: "5px",
                              marginBottom: "3px",
                              color: "#26ae61",
                            }}
                          />
                          {item.states_id?.state}
                        </h6>
                      </div>
                    </div>

                    <div className="job_content">
                      <div className="experience_salary">
                        <span style={{ margin: "0 10px" }}>
                          {/* <HiOutlineCurrencyDollar className="icon_content" /> */}
                          {item.currency_id?.currency}

                        </span>
                        <span>
                          {item.salary_from} - {item?.salary_to}

                        </span>
                        <span className="mx-4">
                          <PiBriefcase className="icon_content" />
                          {item.job_experience?.job_experience}
                        </span>
                      </div>
                      <div className="skills_tags">
                        <p>{item.skills_id?.job_skill}</p>
                      </div>
                      <div className="job-salary dead_line">
                        <span>
                          <GoHistory className="icon_content" />
                          {item.job_expiry_date}
                        </span>
                        <Link to={`/jobs/${item._id}`}>
                          Job Details
                          <FaArrowRight className="arrow_right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <nav aria-label="Page">
            <ul className="pagination" style={{ margin: "18px" }}>
              {count && count?.map((val) => {
                return <li className="page-item "  onClick={() => { getNextPage(val) }}><span className="page-link">{val}</span></li>
              })}
            </ul>
          </nav>
          <div className="col-md-6">
              <div className="showEntry">
                <p>
                  Showing {count1} of {count?.length} entries page
                </p>
              </div>
            </div>
        </div>
      </div>
     
    </>
  );
}
export default RecentJobsAvailable;
