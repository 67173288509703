import { CiSearch } from "react-icons/ci";
import { MdFilterAlt, MdEngineering } from "react-icons/md";
import { RxCaretUp, RxCaretRight } from "react-icons/rx";
import { IoLocationOutline } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";
import { HiBuildingOffice } from "react-icons/hi2";
import { PiSuitcaseSimpleDuotone } from "react-icons/pi";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Jobs() {
  const [data, setData] = useState()
  const [count, setCount] = useState([])
  const [count1, setCount1] = useState(1)
  const getData = async () => {
    try {
      const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/jobs/featured`)
      setData(res.data.data)
      const arrr = []
      for (let i = 0; i < res.data.totalPages; i++) {
        arrr.push(i + 1)
      }
      setCount(arrr);
    } catch (error) {

    }
  }
  useEffect(() => {
    getData()
  }, [])


  const getData2 = async (num) => {
    try {
      const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/jobs/featured?page=${num}`)
      setData(res.data.data)

    } catch (error) {

    }
  }

  const getNextPage = (num) => {
    setCount1(num)
    getData2(num)
  }
  return (
    <>
      <div className="job_up_heading"></div>
      <div className="jobs_main_sec">
        <div className="total_people">
          {/* <h5 className="" style={{ marginLeft: "13px" }}>
            24384 Freshers jobs
          </h5> */}
        </div>
        <div className="job_feild_sec">
          <div className="col-lg-3">
            <div className="job_feild_left_col">
              <div className="">
                <h6 className="d-flex" style={{ alignItems: "center" }}>
                  <MdFilterAlt className="filter" />
                  Filters{" "}
                </h6>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Date posted
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="checkbox_feild">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        All
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Last 24 hour
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Last 3 Days
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Last 7 Days
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Salary
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <p className="mb-0 job_box_title">Minimum monthly salary</p>
                  <h6 className="price">
                    <span>₹60,000+</span>
                  </h6>

                  <div className="mt-2 mb-2">
                    <input type="range" className="range_input" />
                    <div className="price_range">
                      <p>0</p>
                      <p>1.5 Lakhs</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Work Mode
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="checkbox_feild">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Work form home
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Work from office
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Work from feild
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Work Type
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="checkbox_feild">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Full Time
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Part Time
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Work Shift
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="checkbox_feild">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Day Shift
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Night Shift
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Department
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="checkbox_feild">
                    <div className="mt-2 mb-2 search_input_box">
                      <CiSearch className="search_icon" />
                      <input
                        type="text"
                        className="search_input"
                        placeholder="Search..."
                      />
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Admin / Back office /Com...
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Advertising / Communication
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Aviation & Aerospace
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Banking / Insurance / Financial Services
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Beauty, Fitness & Personal ...
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="job_all_list_sec">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "600" }} className="job_box_title">
                      Experience
                    </p>
                  </div>
                  <div>
                    <RxCaretUp className="caretup" />
                  </div>
                </div>

                <div className="mt-2">
                  <p className="mb-0 job_box_title">Minimum work experience</p>
                  <h6 className="price">
                    <span>Any</span>
                  </h6>

                  <div className="mt-2 mb-2">
                    <input type="range" className="range_input" />
                    <div className="price_range">
                      <p>Any</p>
                      <p>20</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 right_col">
            {data && data?.map((item) => {
              return <Link
                to={`/jobs/${item._id}`}
                key={item._id}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="job_feild_right_col mb-3">
                  <div className="date_posted mb-2">
                    <div
                      className="d-flex"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <MdEngineering className="me-2" />
                      <div>
                        <p
                          style={{ fontWeight: "600" }}
                          className="job_box_title"
                        >
                          {item?.job_title}
                        </p>
                        <p className="job_box_title">
                          <small className="mb-zero">{item?.company_id?.name}</small>
                        </p>
                      </div>
                    </div>
                    <div>
                      <RxCaretRight className="caretup" />
                    </div>
                  </div>

                  <div className="job_locat">
                    <IoLocationOutline className="me-2" />
                    <small>{item?.country_id?.country} {item?.states_id?.state} {item?.city_id?.city}</small>
                  </div>

                  <div className="job_locat">
                    <GrCurrency className="me-2" />
                    <small style={{ fontSize: "17px" }}>
                      ₹{item?.salary_from} - ₹{item?.salary_to}
                    </small>
                  </div>

                  <ul className="job_category_list">
                   
                    <li className="jobcategory_list_item">
                      <HiBuildingOffice
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />
                      Full Time
                    </li>
                    <li className="jobcategory_list_item">
                      <HiBuildingOffice
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />
                      Freshers Can Apply
                    </li>
                    <li className="jobcategory_list_item">
                      <HiBuildingOffice
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />
                      No English Require
                    </li>
                  </ul>
                </div>
              </Link>
            })}
            <nav aria-label="Page">
              <ul className="pagination" style={{ margin: "18px" }}>
                {count && count?.map((val) => {
                  return <li className="page-item " onClick={() => { getNextPage(val) }}><span className="page-link">{val}</span></li>
                })}
              </ul>
            </nav>
            <div className="col-md-6">
              <div className="showEntry">
                <p>
                  Showing {count1} of {count?.length} entries page
                </p>
              </div>
            </div>
          </div>

        </div>
      </div >
    </>
  );
}
export default Jobs;
