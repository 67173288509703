import { BiRightArrowAlt } from "react-icons/bi";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";

function ContactForm() {
  return (
    <>
      <section className="contact_form_container py-3 px-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="map_box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.2486829621266!2d77.2778944747489!3d28.562294237268237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38c9ea28f03%3A0xcf085976f2110601!2sJamia%20Millia%20Islamia%2C%20Jamia%20Nagar%2C%20Okhla%2C%20New%20Delhi%2C%20Delhi%20110025!5e0!3m2!1sen!2sin!4v1709634753878!5m2!1sen!2sin"
                className="map"
                style={{
                  width: "800",
                  height: "600",
                  allowfullscreen: "",
                  loading: "lazy",
                  referrerpolicy: "no-referrer-when-downgrade",
                  border: "0",
                }}
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <h4 className="mb-4">Let's get in touch with us</h4>
            <Form>
              <Row className="mb-4">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Full name"
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Email" />
                </Form.Group>
              </Row>

              <Row className="mb-4">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your City"
                    required
                  />
                </Form.Group>
              </Row>

              <FloatingLabel
                controlId="floatingTextarea2"
                label="Enter you message here"
                className="mb-4"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Enter you message here"
                  style={{ height: "200px" }}
                />
              </FloatingLabel>

              <Form.Group className="mb-4">
                <Form.Check
                  required
                  label="Agree to terms and conditions"
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                />
              </Form.Group>

              <div className="">
                <button class="glow-on-hover" type="button">
                  Send Message <BiRightArrowAlt />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactForm;
