import Gallery from "../../components/gallery/Gallery";
import galleryBanner from "../../assets/images/gallery/gallery.jpg";

const data = {
  pagename: "Gallery",
  img: galleryBanner,
};
function GalleryPage() {
  return (
    <>
      <Gallery data={data} />
    </>
  );
}
export default GalleryPage;
