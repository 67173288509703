import CommonBanner from "../common/commonBanner/CommonBanner";

function Faqs({ data, faqs, setFaqs }) {
  const openPera = (id) => {
    const maped = faqs.map((item) => {
      if (item.id == id) {
        return { ...item, isActive: !item.isActive };
      }
      return item;
    });
    setFaqs(maped);
  };
  return (
    <>
      <CommonBanner data={data} />

      <section className="faqs_main_sec">
        <div className="faqs_container">
          <div className="faqs_heading_sec">
            <h1>Questions</h1>
          </div>

          {faqs?.map((item) => {
            return (
              <article className="question mt-5" key={item.id}>
                <header>
                  <h5>{item.question}</h5>
                  <button
                    className="question-btn"
                    onClick={() => {
                      openPera(item.id);
                    }}
                  >
                    {!item.isActive ? "+" : "-"}
                  </button>
                </header>
                {item.isActive && <p className="answer">{item.answer}</p>}
              </article>
            );
          })}
        </div>
      </section>
    </>
  );
}
export default Faqs;
