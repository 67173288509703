import { Link } from "react-router-dom";

function CommonBanner({ data }) {
  return (
    <>
      <section
        className="common_banner_sec"
        style={{ backgroundImage: `url(${data?.img})` }}
      >
        <div className="back_home_container">
          <h1>{data?.pagename}</h1>
          <div className="back-home-box px-2">
            <h6>
              <span>
                <Link to="/home">Home</Link> | {data?.pagename}
              </span>
            </h6>
          </div>
        </div>
      </section>

      <div className="hero-shape d-none d-lg-block">
        <span className="circle"></span>
        <span className="circle circle-yellow"></span>
        <span className="shape-plus">+</span>
      </div>
    </>
  );
}
export default CommonBanner;
