import { Link, useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo/henna-logo.png"
import { useState } from "react"
import axios from "axios"
import { Loaders } from "../Loaders"
function LoginPage() {
    const [state, setState] = useState({
        email: "",
        password: ""
    })
    const [loader, setLoader] = useState(false)
    const handleChange = (e) => {
        const clone = { ...state }
        clone[e.target.name] = e.target.value
        setState(clone)
    }
    const Navigate = useNavigate()
    const submitData = async () => {
        setLoader(true)
        try {
            const res = await axios.post('https://abaris-j-p-frontend.vercel.app/front/user/login' , state)
            console.log(res.data._id);
            window.localStorage.setItem('userId' , res.data._id)
            window.localStorage.setItem('token' , res.data.token)
            window.localStorage.setItem('firstname' , res.data.firstname)
            window.localStorage.setItem('lastname' , res.data.lastname)
            window.localStorage.setItem('mobile' , res.data.mobile)
            window.localStorage.setItem('email' , res.data.email)
            window.localStorage.setItem('isLogin' , true)
            window.location.reload()
            // alert('Login successful')
            Navigate('/')
        } catch (error) {
            alert('Error Found')
        }
        setLoader(false)
    }
    return <div>
        {loader && <Loaders />}
        <div className="job_up_heading"></div>
        <div className="blog_container">
            <div className="blog_heading_sec">
                <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Login Page</h1>
            </div>

            <div className="login-page">
                <div className="form">
                    <div className="" style={{width:"100%"}}>
                        <img src={logo} alt="" style={{width:"50%" ,margin:"0 auto"}} className="logo" />
                    </div>
                    <form className="login-form">
                        <input type="text" placeholder="email" value={state.email} onChange={handleChange} name="email"/>
                        <input type="password" placeholder="password" value={state.password} onChange={handleChange} name="password"/>
                        <button onClick={submitData} type="button">login</button>
                        <p className="message">
                            Not registered? <Link to="/signin">Create an account</Link>
                        </p>
                    </form>
                </div>
            </div>

        </div>
    </div>
}
export default LoginPage