import CommonBanner from "../common/commonBanner/CommonBanner";
import blogImg1 from "../../assets/images/blog/jobseeker/blog-1.jpg"
import { CiSearch } from "react-icons/ci";

function Blog({ data }) {
  return (
    <>
      <CommonBanner data={data} />

      <div className="blog_container">
        <div className="blog_heading_sec">
          <h1 style={{ fontWeight: "600", fontSize: "3rem" }}>Blog</h1>
        </div>

        <div className="blog_main_sec">
          <div className="">
            <div className=" row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="bloginner">
                      <div className="postimg">
                        <img src={blogImg1} alt="" />
                      </div>
                    </div>
                    <div>
                      <h5>Featured -12 MAY, 2023</h5>
                      <h4>Print, publishing qu..</h4>
                      <a href="#">Continue Reading
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="bloginner">
                      <div className="postimg">
                        <img src={blogImg1} alt="" />
                      </div>
                    </div>
                    <div>
                      <h5>Featured -12 MAY, 2023</h5>
                      <h4>Print, publishing qu..</h4>
                      <a href="#">Continue Reading
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="bloginner">
                      <div className="postimg">
                        <img src={blogImg1} alt="" />
                      </div>
                    </div>
                    <div>
                      <h5>Featured -12 MAY, 2023</h5>
                      <h4>Print, publishing qu..</h4>
                      <a href="#">Continue Reading
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="blog-sidebar ps-xl-4 md-mt-60" >
                  <form action="#" className="search-form position-relative mb-50 lg-mb-40" style={{marginBottom:"20px"}}>
                    <input placeholder="Search..." type="text" />
                    <button>
                    <CiSearch />

                    </button>
                  </form>
                  <div className="category-list mb-60 lg-mb-40">
                    <h3 className="sidebar-title">Category</h3>
                    <ul className="style-none">
                      <li>
                        <a href="#">Education (3)</a>
                      </li>
                      <li>
                        <a href="#">Information (4)</a>
                      </li>
                      <li>
                        <a href="#">Interview (2)</a>
                      </li>
                      <li>
                        <a href="#">Speaking (8)</a>
                      </li>
                      <li>
                        <a href="#">Skill (5)</a>
                      </li>
                      <li>
                        <a href="#">Developer (3)</a>
                      </li>
                      <li>
                        <a href="#">Account (7)</a>
                      </li>
                    </ul>
                  </div>
                 
                </div>

              </div>

            </div>
          </div>
          {/* <div className="blog_details_list">kjfdk </div> */}
        </div>
      </div>
    </>
  );
}
export default Blog;
