import CommonBanner from "../common/commonBanner/CommonBanner";

import { galleryData } from "./GalleryApi";
import { useState } from "react";

function Gallery({ data }) {
  const [galleryApi, setGalleryApi] = useState(galleryData);

  return (
    <>
      <CommonBanner data={data} />
      <div className="container-fluid mt-5 mb-5">
        <div className="gallery_img_wrap">
          {galleryApi.map((item) => {
            return (
              <div className="gallery_box">
                <img src={item.img} alt="" className="gallery_img1" />
                <div className="img_hover_effect"></div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default Gallery;
