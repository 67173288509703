import CommonBanner from "../common/commonBanner/CommonBanner";
import img1 from "../../assets/images/aboutus-img/about us 2.jpg";
import img2 from "../../assets/images/aboutus-img/about-3.webp";
import { useEffect } from "react";

function AboutUs({ data }) {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  return (
    <>
      <CommonBanner data={data} />

      <section className="about_us_main_sec">
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="about_img">
                <div className="sec_box_one">
                  <img src={img1} alt="" className="img-1" />
                </div>
                <div className="sec_box_two">
                  <img src={img2} alt="" className="img-2" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutus_details_box">
                <h5>About Us</h5>
                <h3>
                  OUR GOAL IS TO PROVIDE QUALITY AND TIMELY MANPOWER TO OUR
                  CLIENTS.
                </h3>
                <p className="p-text">
                  Henna International, introduces ourselves as professional
                  Head-Hunters, International Recruitment Firms, Overseas
                  Recruitment Agencies, and HR Consultants in India. We have an
                  excellent track record of recruiting thousands of management
                  professionals, technical and non-technical personnel, and
                  skilled, semi-skilled, and unskilled workers for corporate
                  giants in the Middle East (especially in Saudi Arabia, Oman,
                  Kuwait, UAE, and Qatar)Henna International is your one-stop
                  shop for all overseas manpower requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="about_main_details_sec">
            <p>
              Our main goal is to achieve "customer satisfaction" through
              quality and professional service with a personal touch. Henna
              International Human Resource Consultants' has maintained a high
              level of quality in recruitment service for clients across the
              globe. Our recruitment support divisions are tailored to fit the
              needs of our clients. We provide temporary/permanent and part-time
              / contract staffing as well as personnel for talents, exhibitions,
              conventions, and promotions. The businesses we acquired are mostly
              referrals from our existing clients. As a professionally managed
              recruitment consultancy firm, we focus on the growing needs of our
              clients and provide specially customized and personalized Human
              Resource Consultancy to our clients and candidates. Our
              organizations need an employee who matches their organizational
              size, culture, work environment, and business expectations and
              above all with compatible behavior and a healthy attitude. Over 3
              decades in this profession, our recruitment centers have access to
              top-quality expertise worldwide. Henna International Pvt. Ltd is
              an ISO-certified Global Human Resource Consultants, based in
              Delhi, India having branches in Mumbai, Patna, Siwan, UAE & Saudi
              Arabia with associate offices in all metro cities in India.
            </p>
            <p>
              Henna International is approved by the Ministry of External
              Affairs, Govt. of India vide Regn. No. B-0650/ DEL / COM / 1000+
              /5 / 9274 / 2017. Henna International is one of the few Human
              Resource companies in the industry that have a unique and
              wide-ranging bouquet of services as we have crafted it to
              perfection to meet international recruitment standards, ensuring
              positive commercial outcomes for our global clients.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUs;
