
import img1 from "../../assets/images/gallery/office img 1.jpg"
import img2 from "../../assets/images/gallery/office img 2.jpg"
import img3 from "../../assets/images/gallery/office img 3.jpg"
import img4 from "../../assets/images/gallery/office img 4.jpg"
import img5 from "../../assets/images/gallery/office img 5.jpg"
import img6 from "../../assets/images/gallery/office img 6.jpg"
import img7 from "../../assets/images/gallery/gallery img 7.jpg"
import img8 from "../../assets/images/gallery/gallery img 8.jpg"
import img9 from "../../assets/images/gallery/gallery img 9.jpg"


export const galleryData = [
  {
    id: "1",
    img: img1,
  },
  {
    id: "2",
    img: img2,
  },
  {
    id: "3",
    img: img3,
  },
  {
    id: "4",
    img: img4,
  },
  {
    id: "5",
    img: img5,
  },
  {
    id: "6",
    img: img6,
  },
  {
    id: "7",
    img: img7,
  },
  {
    id: "8",
    img: img8,
  },
  {
    id: "9",
    img: img2,
  },
];
