export const faqsApi = {
  heading: "Questions",
  faqQuestion: [
    {
      isActive: false,
      id: "1",
      question: "How do I create an account on the job portal?",
      answer:
        "To create an account, click on the 'Sign Up' button and follow the instructions to provide your details and create a profile.",
    },
    {
      isActive: false,
      id: "2",
      question: "How can I search for job opportunities?",
      answer:
        "You can use the search bar to enter keywords, job titles, or companies. Additionally, you can filter results based on location, industry, and job type.",
    },
    {
      isActive: false,
      id: "3",
      question: "How do I apply for a job?",
      answer:
        "Once you find a suitable job, click on the 'Apply Now' button, and follow the application process specified by the employer.",
    },
    {
      isActive: false,
      id: "4",
      question: "Can I edit my resume after submission?",
      answer:
        "Yes, you can edit your resume at any time. Navigate to your profile, select the resume section, and make the necessary changes.",
    },
  ],
};
