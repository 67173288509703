import { useEffect, useState } from "react";
import Aos from "aos";
import { specilizationApi } from "./SpecializationApi";

function Specialization() {
  const [data, setData] = useState(specilizationApi);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="specialization_container">
        <div className="specil_heading_title_sec" data-aos="fade-down">
          <h1>{data.heading}</h1>
          <p>{data.title}</p>
        </div>
        <div className="speciliz_provide_container" data-aos="fade-down">
          {data.data?.map((item) => {
            return (
              <div className="provide_wrap_box" data-aos="fade-up">
                <div className="speci_main_wrap_box">
                  <div className="provid_related_job_img_box">
                    <img
                      src={item.img}
                      alt=""
                      className="provid_related_job_img"
                    />
                  </div>
                  <div className="provide_spe_details_box">
                    <h5>{item.heading}</h5>
                    <p>{item.title}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default Specialization;
