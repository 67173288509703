import { MdOutlineCardGiftcard } from "react-icons/md";
import { LuUserCircle2 } from "react-icons/lu";
import { FaFileAlt, FaCoffee } from "react-icons/fa";
import CountUp from "react-countup";

function CounterArea() {
  return (
    <>
      <section className="counter_area_sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single_fact">
                <MdOutlineCardGiftcard className="fal" />
                <div className="fact_text">
                  <h6 className="counter_large_text">
                    <CountUp start={0} end={3560} duration={2} />
                  </h6>
                  <span>Available Jobs</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single_fact">
                <LuUserCircle2 className="fal" />
                <div className="fact_text">
                  <h6 className="counter_large_text">
                    <CountUp start={0} end={8563} duration={2} />
                  </h6>
                  <span>Employees</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single_fact">
                <FaFileAlt className="fal" />
                <div className="fact_text">
                  <h6 className="counter_large_text">
                    <CountUp start={0} end={4630} duration={2} />
                  </h6>
                  <span>CV/Resume 420</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single_fact">
                <FaCoffee ardGiftcard className="fal" />
                <div className="fact_text">
                  <h6 className="counter_large_text">
                    <CountUp start={0} end={420} duration={2} />
                  </h6>
                  <span>Campaigns</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CounterArea;
