import AboutUsHome from "./aboutusHome/AboutusHome";
import Specialization from "./specialization/Specialization";

import { useEffect } from "react";
import Aos from "aos";
import BannerDetails from "./bannerDetail/BannerDetails";
import PopularCategories from "./popularCategories/PopularCategories";
import CounterArea from "./counterArea/CounterArea";
import RecentJobsAvailable from "./recentJobs/RecentJobsAvailable";

import { recentJobsData } from "./recentJobs/recentJobsApi";
import { useState } from "react";
import Testimonial from "./testimonial/Testimonial";
import CompanysFea from "./comapnes/CompanysFea";

function Home() {
  const [jobData, setJobData] = useState(recentJobsData);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="home_main_sec">
        <BannerDetails />
      </div>

      <AboutUsHome />
      <PopularCategories />
      <RecentJobsAvailable jobData={jobData} />
      <CompanysFea jobData={jobData} />
      <CounterArea />
      <Testimonial />
      <Specialization />
    </>
  );
}
export default Home;
