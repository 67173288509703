import CommonBanner from "../common/commonBanner/CommonBanner";
import indiaFlag from "../../assets/images/contact/country.png";
import ksa from "../../assets/images/contact/flag.png";
import uae from "../../assets/images/contact/united-arab-emirates.png";

import { useState } from "react";
import { IndcountData, ksaCountData, uaeCountData } from "./BranchCountApi";
import ContactForm from "./contactForm/ContactForm";

function Contact({ data }) {
  const [ind, setInd] = useState(IndcountData);
  // console.log(ind.list);
  return (
    <>
      <CommonBanner data={data} />
      <div className="contact-main-box">
        <div className="contact_absolute">
          <div className="branch_sec">
            <div className="count_container">
              <button
                className="branch_btn"
                type="button"
                onClick={() => {
                  setInd(IndcountData);
                }}
              >
                <img src={indiaFlag} alt="" className="contact-flag-icon" />{" "}
                INDIA
              </button>

              <button
                className="branch_btn"
                type="button"
                onClick={() => {
                  setInd(ksaCountData);
                }}
              >
                <img src={ksa} alt="" className="contact-flag-icon" />
                KSA
              </button>

              <button
                className="branch_btn"
                type="button"
                onClick={() => {
                  setInd(uaeCountData);
                }}
              >
                <img src={uae} alt="" className="contact-flag-icon" />
                UAE
              </button>
            </div>
          </div>
        </div>

        <section className="office_ad">
          <div className="heading">
            <h2>
              <img src={ind.img} alt="" className="contact-flag-icon" />
              {ind.title}
            </h2>
          </div>
          <section className="cont">
            {ind.list.map((item) => {
              console.log(item);
              return (
                <div className="box" key={item.id}>
                  <img src={item.locationPin} alt="" className="locationpin" />
                  <h5>{item.heading}</h5>
                  <p>{item.address}</p>
                  <div className="f-start">
                    <p>Email:{item.email}</p>
                    <p>Phone:{item.phone1}</p>
                  </div>
                </div>
              );
            })}
          </section>
        </section>

        <ContactForm />
      </div>
    </>
  );
}
export default Contact;
