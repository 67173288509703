import Blog from "../../components/blog/Blog";
import blogImg from "../../assets/images/blog/blog.jpg";

const data = {
  pagename: "Blog",
  img: blogImg,
};
function BlogPage() {
  return (
    <>
      <Blog data={data} />
    </>
  );
}
export default BlogPage;
