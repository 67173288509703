import { FiMapPin } from "react-icons/fi";
import { IoCallOutline } from "react-icons/io5";
import { GoMail } from "react-icons/go";

import { FaFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="main_footer">
        <div className="footer_upper_box">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="address_box">
                  <h2 className="address_box_heading">office</h2>
                  <h3 className="address_box_title">
                    Henna International Pvt. Ltd.
                  </h3>
                  <div className="sec2-text d-flex">
                    <span className="material-symbols-outlined">
                      <FiMapPin className="material-symbols" />
                    </span>
                    <p>
                      57-B, Lane 3, opp. Deep Sweets Corner, Bharat Nagar, New
                      Friends Colony, New Delhi – 110025
                    </p>
                  </div>

                  <div className="sec2-text d-flex">
                    <span className="material-symbols-outlined">
                      <IoCallOutline className="material-symbols" />
                    </span>
                    <p>: +91 9870353974, +91 11-41049392</p>
                  </div>

                  <div className="sec2-text d-flex">
                    <div className="material d-flex">
                      <span className="material-symbols-outlined">
                        <GoMail className="material-symbols" />
                      </span>
                      <p>contact@hennainternational.co.in</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="address_box">
                  <h2 className="address_box_heading">Quick Link</h2>
                  <ul className="footer_quick_links_list">
                    <li>
                      <a href="">Recruitment Services</a>
                    </li>
                    <li>
                      <a href="">Talent Sourcing & Acquisition</a>
                    </li>
                    <li>
                      <a href="">Access To Skill Center</a>
                    </li>
                    <li>
                      <a href="">Saudi Visa Stamping</a>
                    </li>
                    <li>
                      <a href="">Kuwait Visa Stamping</a>
                    </li>
                    <li>
                      <a href="">Dubai Visa Stamping</a>
                    </li>
                    <li>
                      <a href="">Air Ticketing Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="address_box">
                  <h2 className="address_box_heading">Quick Link</h2>
                  <ul className="footer_quick_links_list">
                    <li>
                      <a href="">Manpower Services</a>
                    </li>
                    <li>
                      <a href="">Hajj & Umrah Services</a>
                    </li>
                    <li>
                      <a href="">Document Attestation Services</a>
                    </li>
                    <li>
                      <a href="">Tour & Travel Services</a>
                    </li>
                    <li>
                      <a href="">Immigration Services</a>
                    </li>
                    <li>
                      <a href="">Visa Stamping Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                {" "}
                <div className="address_box">
                  <h2 className="address_box_heading">other links</h2>
                  <ul className="footer_quick_links_list">
                    <li>
                      <a href="">home</a>
                    </li>
                    <li>
                      <Link to="/gallery">gallery</Link>
                    </li>
                    <li>
                      <a href="">about us</a>
                    </li>
                    <li>
                      <a href="">our client</a>
                    </li>
                    <li>
                      <a href="">contact us</a>
                    </li>
                    <li>
                      <Link to="/faqs">Faqs</Link>
                    </li>
                  </ul>
                  <div className="icons">
                    <h3 className="address_box_title">connect with us</h3>
                    <ul className="d-flex connect_icons_list">
                      <li>
                        <a href="">
                          <FaFacebook className="connect_icons_list_item" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaInstagramSquare className="connect_icons_list_item" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <FaSquareWhatsapp className="connect_icons_list_item" />
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <TfiYoutube className="connect_icons_list_item" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <p>
            ©2023 Henna International Pvt. Ltd. .All Rights Reserved, Powered By
            <a
              target="_blank"
              href="https://www.abarissoftech.com/"
              className="abarish"
            >
              Abaris Softech
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;
