import {
  FaBezierCurve,
  FaLaptopCode,
  FaBriefcaseMedical,
  FaClinicMedical,
  FaGraduationCap,
} from "react-icons/fa";
import { IoMdArrowRoundForward } from "react-icons/io";
import { GiAcorn, GiBroom } from "react-icons/gi";
import { ImBullhorn } from "react-icons/im";
import { FaTowerBroadcast } from "react-icons/fa6";
import Aos from "aos";
import { useEffect } from "react";

function PopularCategories() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="categories_sec">
      <div className="category_heading" data-aos="fade-down">
        <h6>
          <strong>Popular Categories</strong>
        </h6>
        <p>
          <small>
            Explore our Job Portal's to streamline your job search. From the
            cutting-edge world of Technology to the strategic realm of Finance,
          </small>
        </p>
      </div>
      <div className="row mt-5" data-aos="fade-up">
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaBezierCurve className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Graphics Design</h4>
              <span>52 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <GiAcorn className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Graphics Design</h4>
              <span>52 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaLaptopCode className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Movie & Films</h4>
              <span>304 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <ImBullhorn className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Graphics Design</h4>
              <span>308 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaTowerBroadcast className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>It/Web Consulting</h4>
              <span>69 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaBriefcaseMedical className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Business Group</h4>
              <span>66 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <GiBroom className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Cleaning Services</h4>
              <span>183 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaClinicMedical className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Culinary Arts</h4>
              <span>89 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="categories-wrapper pos-rel">
            <div className="categori_icon">
              <FaGraduationCap className="categori_icon1" />
            </div>
            <div className="categories-text">
              <h4>Private Tutors</h4>
              <span>89 Available Jobs</span>
            </div>
            <div className="cat-button">
              <a href="">
                <IoMdArrowRoundForward />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default PopularCategories;
