import speciImg1 from "../../../assets/images/specilization/Construction & Maintenance.jpg";
import speciImg2 from "../../../assets/images/specilization/Oil and Gas.jpg";
import speciImg3 from "../../../assets/images/specilization/Healthcare.jpg";
import speciImg4 from "../../../assets/images/specilization/Facility & Management.jpg";
import speciImg5 from "../../../assets/images/specilization/Petrochemicals.jpg";
import speciImg6 from "../../../assets/images/specilization/Manufacturing 1.jpg";
import speciImg7 from "../../../assets/images/specilization/Engineering and project Management.jpg";
import speciImg8 from "../../../assets/images/specilization/Engineering and project Management.jpg";
import speciImg9 from "../../../assets/images/specilization/Power and Utility 1.jpg";
import speciImg10 from "../../../assets/images/specilization/IT and Telecommunications.jpg";
import speciImg11 from "../../../assets/images/specilization/Commercial and Retail.jpg";
import speciImg12 from "../../../assets/images/specilization/Teaching and Education Administration.jpg";

export const specilizationApi = {
  heading: "Our Specialization",
  title:
    "We specializes in providing recruitment services to the following list of sectors",

  data: [
    {
      id: "1",
      img: speciImg1,
      heading: "Construction & Maintenance",
      title:
        "Get a career in World’s leading construction and maintenance companies",
    },
    {
      id: "2",
      img: speciImg2,
      heading: "Oil and Gas",
      title: "Get a career in World’s leading Oil and Gas Companies.",
    },
    {
      id: "3",
      img: speciImg3,
      heading: "Healthcare",
      title: "Get jobs at Healthcare all over the world.",
    },
    {
      id: "4",
      img: speciImg4,
      heading: "Facility & Management",
      title:
        "Get a chance for being employed to provide Facility & Management to World’s leading industries..",
    },
    {
      id: "5",
      img: speciImg5,
      heading: "Petrochemicals",
      title: "Get a career in World’s leading Petrochemical Companies.",
    },
    {
      id: "6",
      img: speciImg6,
      heading: "Manufacturing",
      title:
        "Get employed in World’s leading Manufacturing companies in all over the world.",
    },
    {
      id: "7",
      img: speciImg7,
      heading: "Engineering and project Management",
      title:
        "Get a chance for being employed to provide hospitality to World’s leading industries.",
    },
    {
      id: "8",
      img: speciImg8,
      heading: "Engineering Procurement",
      title: "Get a career in World’s leading Petrochemical Companies.",
    },
    {
      id: "9",
      img: speciImg9,
      heading: "Power and Utility",
      title:
        "Get employed in World’s leading Manufacturing companies in all over the world.",
    },
    {
      id: "10",
      img: speciImg10,
      heading: "IT and Telecommunications",
      title:
        "Get a chance for being employed to provide hospitality to World’s leading industries.",
    },
    {
      id: "11",
      img: speciImg11,
      heading: "Commercial and Retail",
      title: "Get a career in World’s leading Petrochemical Companies.",
    },
    {
      id: "12",
      img: speciImg12,
      heading: "Teaching and Education Administration",
      title:
        "Get employed in World’s leading Manufacturing companies in all over the world.",
    },
  ],
};
