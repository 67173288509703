
import { IoArrowForward, IoLocationOutline } from "react-icons/io5";
import { FaCalendarWeek } from "react-icons/fa";
import { PiBriefcase } from "react-icons/pi";
import { GoHistory } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
function CompanysFea({ jobData }) {
    const [data, setData] = useState()
    const [count, setCount] = useState([])
    const [count1, setCount1] = useState(1)
    const getData = async () => {
        try {
            const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/company/featured`)
            setData(res.data.data)
            const arrr = []
            for (let i = 0; i < res.data.totalPages; i++) {
                arrr.push(i + 1)
            }
            setCount(arrr);
        } catch (error) {

        }
    }
    useEffect(() => {
        getData()
    }, [])

    const getDataw = async (num) => {
        try {
            const res = await axios.get(`https://abaris-j-p-frontend.vercel.app/front/company/featured?page=${num}`)
            setData(res.data.data)
            
        } catch (error) {

        }
    }

    const getNextPage = (num) => {
        setCount1(num)
        getDataw(num)
    }
    return (
        <>
            <div className="job_area">
                <div className="container">
                    <div
                        className="row"
                        style={{ alignItems: "center", marginBottom: "45px" }}
                    >
                        <div className="col-xl-5">
                            <div className="section-title">
                                <h2>Featured Companies</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    {!data?.length && <h5>NO DATA</h5>}
                        {data && data.map((item) => {
                            return (
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <div className="job_wrapper">

                                        <Link to={`/company/${item._id}`}>
                                            <div className="job-instructor-profile">
                                                <div className="job-instructor-img">
                                                    <img src={jobData.jobs[0].img} alt="" />
                                                </div>
                                                <div className="job-instructor-title">
                                                    <h4>
                                                        <a href="">{item.name}</a>
                                                    </h4>
                                                    <a href="#" className="company_name">
                                                        {item.location ? item.location : item?.address} {item.icon}
                                                    </a>
                                                    <h6 className="florida">
                                                        <IoLocationOutline
                                                            style={{
                                                                fontSize: "20px",
                                                                marginRight: "5px",
                                                                marginBottom: "3px",
                                                                color: "#26ae61",
                                                            }}
                                                        />
                                                        {item.city_id?.city}
                                                    </h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            );
                        })}
                    </div>
                    <nav aria-label="Page">
                    <ul className="pagination" style={{ margin: "18px" }}>
                        {count && count?.map((val) => {
                            return <li className="page-item" onClick={() => { getNextPage(val) }}><span className="page-link">{val}</span></li>
                        })}
                    </ul>
                </nav>
                <div className="col-md-6">
                    <div className="showEntry">
                        <p>
                            Showing {count1} of {count?.length} entries page
                        </p>
                    </div>
                </div>
                </div>
               
            </div>
        </>
    );
}
export default CompanysFea;
